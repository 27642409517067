$imperial-red: #E63946;
$honeydew: #F1FAEE;
$powder-blue: #A8DADC;
$celadon-blue: #457B9D;
$prussian-blue: #1D3557;
$white: #FFFFFF;

html {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: $celadon-blue;
}

body {
  max-width: 600px;
  margin: 10px auto;
  background-color: $white;
  padding: 20px 20px 20px 20px;
  border: 5px solid black;
}

h1 {
  margin: 0;
  padding: 20px 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

a {
  color: $prussian-blue;
}

.current {
  color: $imperial-red;
}
